.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-size: contain;
  background-repeat: no-repeat;
  background: linear-gradient(169deg, #037d57 0%, #00f2a7 100%);
  overflow: hidden;

  .header {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 25px 25px;

    .logo {
      width: 100%;
      height: 60px;
      img {
        width: 49px;
        height: 38px;
      }
    }

    .logo2 {
      position: absolute;
      width: 100%;
      align-self: left;
    }

    .text1 {
      position: absolute;
      color: white;
      font-size: 22px;
      font-family: arial, sans-serif;
      font-weight: 700;
    }

    @media (max-width: 768px) {
      .header {
        padding: 12px 32px;
      }
    }

    @media (min-width: 768px) {
      .share {
        display: none;
      }
    }

    @media (max-width: 480px) {
      .header {
        padding: 12px 32px;
        display: flex;
        justify-content: space-between;
      }

      .logo {
        img {
          width: 49px;
          height: 38px;
        }
      }

      .share {
        width: 54%;
      }
    }
  }
}

.banner {
  color: white;
  padding: 2em;
  border-radius: 12px;
  max-width: 580px;
  width: 100%;
  margin-top: 130px;
  margin-left: 50px;

  h1 {
    margin-bottom: 16px;
    font-size: 2em;
  }
}

button {
  display: flex;
  align-items: center;
  background: #b2aeff !important;
  border: none;
  padding: 10px 16px !important;
  border-radius: 48px;
  /*optional*/
  font-family: arial, sans-serif;
  font-weight: 600;
  font-size: 1em;
  /*input has OS specific font-family*/
  color: #191c1f;
  cursor: pointer;
}

@media (max-width: 720px) {
  .banner {
    max-width: none;
    width: 100%;
    margin-top: 100px;
    margin-left: 0px;
    font-size: 14px;

    h1 {
      font-size: 24px;
    }
  }
}

.footer {
  position: fixed;
  display: flex;
  width: 100%;
  flex-direction: column;
  left: 0;
  bottom: 0;
  color: white;
  text-align: center;
  padding-bottom: 2em;

  @media (min-width: 769px) {
    .bgBox {
      display: none !important;
    }
  }

  .bgBox {
    display: flex;
    min-height: 301px;
    min-width: 354px;
    position: relative;
    align-self: center;
    bottom: 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  p {
    margin-bottom: 1em;
  }

  img {
    width: 16%;
  }

  .nextGame {
    font-family: arial, sans-serif;
    background-color: #262626;
    color: white;
    opacity: 0.8;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 2em;

    .textContainer {
      width: 70%;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        text-align: center;
      }
    }

    @media (max-width: 480px) {
      .textContainer {
        width: 100%;
      }
    }
  }

  .terms {
    margin-top: 1em;
    a {
      font-size: 0.8em;
      color: lightgray;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 480px) {
    img {
      width: 42%;
    }
  }
}

.footerText {
  font-size: 12px;
  margin: 0.1em 2em;
  color: #d1d5da;
  text-align: left;
}

#SportBuff-container {
  overflow: hidden;
}
